import { Controller } from "@hotwired/stimulus"
import Sortable from 'sortablejs';
import axios from "axios"
import iziToast from "izitoast";
import { make } from "../lib/dom";

class SortableDragable extends Controller {
  urlValue!: String
  pageValue!: Number
  numberTargets!: HTMLElement[]
}

export default class extends (Controller as typeof SortableDragable) {
  static values = { url: String, page: Number }
  static targets = ["number"]
  loadingEl = make('div', ['absolute', 'inset-0'])

  connect(): void {
    this.loadingEl.addEventListener('mousedown', (e) => e.preventDefault())

    Sortable.create(this.element, {
      onEnd: (event) => {
        this.recalculateIndex()
        const element = event.item as HTMLElement;
        const id = element.getAttribute("record-id")
        let previousId = null;
        const nextElement = element.nextSibling as HTMLElement

        if (nextElement) {
          previousId = nextElement.getAttribute("record-id")
        }

        this.updateOrder({ id: id, previous_id: previousId })
      }
    })
  }

  recalculateIndex() {
    if (this.numberTargets.length == 0) return;

    for(let i = 0; i < this.numberTargets.length; i++) {
      this.numberTargets[i].innerHTML = String(i + 1 + (this.pageValue as number - 1))
    }
  }

  updateOrder(data) {
    if (!data || !this.urlValue) return;
    const csrfMeta = document.querySelector("[name=csrf-token]") as HTMLMetaElement;
    this.loadingScreen(true)

    axios.post(String(this.urlValue), data, {
      headers: {
        "X-CSRF-Token": csrfMeta?.content
      }
    }).catch(() => {
      iziToast.error({ title: 'Error', message: 'Failed to reorder', position: 'topRight'})
    }).finally(() => {
      this.loadingScreen(false)
    })
  }

  loadingScreen(isLoading: boolean) {
    if (isLoading) {
      this.element.appendChild(this.loadingEl)
      this.element.style.cursor = 'progress'
    } else {
      this.element.removeChild(this.loadingEl)
      this.element.style.cursor = 'default'
    }
  }
}
